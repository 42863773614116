"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.employeeRoles = exports.Employee = void 0;
const employeeRoles = exports.employeeRoles = ['administrator', 'animator', 'employee'];
class Employee {
  constructor(id, firstName, lastName, professionalEmail, eulaAccepted, receiveNewsletter, privacyPolicyAccepted, role, isFMDBeneficiary, user, partner, surveyAnswers) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.professionalEmail = professionalEmail;
    this.eulaAccepted = eulaAccepted;
    this.receiveNewsletter = receiveNewsletter;
    this.privacyPolicyAccepted = privacyPolicyAccepted;
    this.role = role;
    this.isFMDBeneficiary = isFMDBeneficiary;
    this.user = user;
    this.partner = partner;
    this.surveyAnswers = surveyAnswers;
  }
  get isAdmin() {
    return ['administrator', 'animator'].includes(this.role);
  }
}
exports.Employee = Employee;