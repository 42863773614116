import { IEnvironment, TAdministrativeLevel, defaultEnvironment } from '@geovelo-frontends/commons';

let _environment: IEnvironment & {
  config: {
    maxAdministrativeLevel: Extract<TAdministrativeLevel, 'world' | 'country' | 'region'>;
    minAdministrativeLevel: Exclude<TAdministrativeLevel, 'world'>;
    defaultCountryCode: string;
    defaultZoneCode: string;
  };
} = {
  ...defaultEnvironment,
  source: '',
  apiKey: '3844e2ce-759a-4a43-b02b-b9b5630f2271',
  config: {
    maxAdministrativeLevel: 'country',
    minAdministrativeLevel: 'city',
    defaultCountryCode: 'fr',
    defaultZoneCode: 'île-de-france',
  },
};

if (process.env.REACT_APP_CONFIG === 'world') {
  _environment = {
    ..._environment,
    config: {
      ..._environment.config,
      maxAdministrativeLevel: 'world',
      minAdministrativeLevel: 'country',
    },
  };
} else if (!process.env.REACT_APP_CONFIG || process.env.REACT_APP_CONFIG === 'france') {
  //
}

export const environment = _environment;
