import 'moment/locale/fr';
import {
  AppService,
  CyclabilityZone,
  CyclabilityZoneService,
  Partner,
  Period,
  PoiCategory,
  PoiCategoryService,
  Report,
  ReportType,
  ReportTypeService,
  RouteReport,
  User,
  UserService,
  defaultLanguage,
  last12Months,
} from '@geovelo-frontends/commons';
import { MatomoProvider } from '@jonkoops/matomo-tracker-react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import moment, { Moment } from 'moment';
import { SnackbarProvider } from 'notistack';
import { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import { environment } from '../environment';

import { AppContext, world, worldId } from './context';
import i18n from './i18n';
import matomoInstance from './matomo';
import Router from './router';
import { TTab } from './router/tabs';
import theme from './theme';

import { Map } from '!maplibre-gl';

Chart.register(
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
);

Chart.defaults.font.family = '"Roboto", "Helvetica", "Arial", sans-serif';

function App(): JSX.Element {
  const [map, setMap] = useState<Map>();
  const [currentUser, setCurrentUser] = useState<User | null>();
  const [userPartners, setUserPartners] = useState<Partner[] | null>();
  const [signInDialogOpen, openSignInDialog] = useState(false);
  const [zoneMap, setZoneMap] = useState<{ [id: number]: CyclabilityZone }>({ [worldId]: world });
  const [zoneCodeMap, setZoneCodeMap] = useState<{ [key: string]: number | null }>({});
  const [zoneParentMap, setZoneParentMap] = useState<{ [id: number]: number | null }>({
    [worldId]: null,
  });
  const [zoneChildrenMap, setZoneChildrenMap] = useState<{ [id: number]: number[] | null }>({});
  const [currentZone, setCurrentZone] = useState<CyclabilityZone | null>();
  const [frDpts, setFRDpts] = useState<CyclabilityZone[]>();
  const [statsPeriods, setStatsPeriods] = useState<Array<{ key: string; date: Moment }>>();
  const [selectedStatsKey, selectStatsKey] = useState<string | null>(null);
  const [selectedStatsIndex, selectStatsIndex] = useState<number | null>(null);
  const [countryCode, setCountryCode] = useState<string | null>();
  const [zoneCode, setZoneCode] = useState<string | null>();
  const [reports, setReports] = useState<Report[]>();
  const [reportTypes, setReportTypes] = useState<ReportType[]>();
  const [allReportTypes, setAllReportTypes] = useState<ReportType[]>();
  const [selectedReport, setSelectedReport] = useState<Report | null | undefined>(null);
  const [selectedPeriod, setSelectedPeriod] = useState<Period>(last12Months);
  const [routeReports, setRouteReports] = useState<RouteReport[]>();
  const [selectedRouteReport, setSelectedRouteReport] = useState<RouteReport | null | undefined>(
    null,
  );
  const [poiCategories, setPoiCategories] = useState<PoiCategory[]>();
  const [currentTab, setCurrentTab] = useState<TTab>('stats');
  const [contributionId, setContributionId] = useState<number | null>(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (currentUser) {
      getUserData();
    } else if (currentUser === null) {
      setUserPartners(null);
    }
  }, [currentUser]);

  async function init() {
    AppService.environment = environment;

    moment.locale(defaultLanguage);

    const user = await UserService.getCurrentUser();
    setCurrentUser(user);

    getReportTypes();
    getPoiCategories();
    if (!process.env.REACT_APP_CONFIG || process.env.REACT_APP_CONFIG === 'france') getFRDpts();

    setInitialized(true);
  }

  async function getReportTypes() {
    try {
      const types = await ReportTypeService.getReportTypes();
      setReportTypes(types.filter(({ isBindToOSM }) => isBindToOSM));
      setAllReportTypes(types);
    } catch (err) {
      console.error(err);
    }
  }

  async function getPoiCategories() {
    try {
      setPoiCategories(await PoiCategoryService.getPoiCategories());
    } catch (err) {
      console.error(err);
    }
  }

  async function getFRDpts() {
    try {
      const { zones: dpts } = await CyclabilityZoneService.getZones({
        administrativeLevel: 'DEPARTMENT',
        countryCode: 'fr',
        rowsPerPage: 100,
        query: '{id, administrative_level, name, stats}',
      });

      setFRDpts(dpts);
    } catch (err) {
      console.error(err);
    }
  }

  async function getUserData() {
    try {
      setUserPartners(
        (await UserService.getPartners()).filter(
          ({ dashboardTabsPermissions: { facilitiesGISFiles } }) => facilitiesGISFiles !== 'none',
        ),
      );
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <AppContext.Provider
      value={{
        map: { current: map },
        user: { current: currentUser, partners: userPartners, signInDialogOpen },
        zone: {
          map: zoneMap,
          codeMap: zoneCodeMap,
          parentMap: zoneParentMap,
          childrenMap: zoneChildrenMap,
          current: currentZone,
          frDpts,
        },
        stats: {
          periods: statsPeriods,
          selectedKey: selectedStatsKey,
          selectedIndex: selectedStatsIndex,
        },
        report: {
          reports,
          types: reportTypes,
          allTypes: allReportTypes,
          selectedReport,
          selectedPeriod,
        },
        routeReport: { reports: routeReports, selectedReport: selectedRouteReport },
        pois: { categories: poiCategories },
        routing: { countryCode, zoneCode, currentTab, contributionId },
        actions: {
          setMap,
          setCurrentUser,
          openSignInDialog,
          setZoneMap,
          setZoneCodeMap,
          setZoneParentMap,
          setZoneChildrenMap,
          setCurrentZone,
          setStatsPeriods,
          selectStatsKey,
          selectStatsIndex,
          setCountryCode,
          setZoneCode,
          setCurrentTab,
          setContributionId,
          setReports,
          setSelectedReport,
          setSelectedPeriod,
          setRouteReports,
          setSelectedRouteReport,
        },
      }}
    >
      <I18nextProvider i18n={i18n}>
        <MatomoProvider value={matomoInstance}>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                maxSnack={1}
              >
                {initialized && (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Router />
                  </LocalizationProvider>
                )}
              </SnackbarProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </MatomoProvider>
      </I18nextProvider>
    </AppContext.Provider>
  );
}

export default App;
