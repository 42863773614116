"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OriginDestinationService = void 0;
var _models = require("../models");
var _http = require("./http");
class OriginDestinationService {
  static async getFlows(_ref) {
    let {
      startId,
      period,
      timePeriod,
      daysOfWeek,
      departureCyclabilityZoneIds,
      arrivalCyclabilityZoneIds
    } = _ref;
    try {
      const body = {
        start_date: period.from.format('YYYY-MM-DD'),
        end_date: period.to.format('YYYY-MM-DD'),
        days_of_week: daysOfWeek,
        hours_of_day: timePeriod
      };
      if (departureCyclabilityZoneIds) body.cyclability_zone_start_ids = departureCyclabilityZoneIds;
      if (arrivalCyclabilityZoneIds) body.cyclability_zone_end_ids = arrivalCyclabilityZoneIds;
      const data = await _http.HttpService.post('originDestination', '/stats/origin_destination', [], [], JSON.stringify(body));
      return data.map((flow, index) => parseFlows(flow, (startId || 1) + index));
    } catch (err) {
      console.error('[OriginDestinationService][getFlows]', err);
      throw err;
    }
  }
}
exports.OriginDestinationService = OriginDestinationService;
function parseFlows(_ref2, id) {
  let {
    cyclability_zone_start_id,
    cyclability_zone_end_id,
    cyclability_zone_start_reference,
    cyclability_zone_end_reference,
    count
  } = _ref2;
  return new _models.Flow(id, cyclability_zone_start_id, cyclability_zone_end_id, count, {
    origin: cyclability_zone_start_reference || '',
    destination: cyclability_zone_end_reference || ''
  });
}