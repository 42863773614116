import { CyclabilityZone, Facilities, useFileSaver, useUnits } from '@geovelo-frontends/commons';
import { Download } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FormControlLabel,
  Skeleton,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Chart } from 'chart.js';
import html2canvas from 'html2canvas';
import {
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../app/context';

const facilities: Array<{ key: Facilities | 'all'; label?: string; labelKey?: string }> = [
  { key: Facilities.Cycleways, labelKey: 'commons.facilities.cycleway_other' },
  { key: Facilities.Greenways, labelKey: 'commons.facilities.greenway_other' },
  { key: Facilities.Lanes, labelKey: 'commons.facilities.lane_other' },
  { key: Facilities.Opposites, labelKey: 'commons.facilities.opposite_other' },
  { key: Facilities.SharedBusways, labelKey: 'commons.facilities.shared_busway_other' },
  { key: Facilities.MixedFacilities, labelKey: 'commons.facilities.mixed_facilities_other' },
  { key: 'all', label: 'Total' },
];

function EvolutionDialog({
  onClose,
  ...props
}: Omit<DialogProps, 'onClose'> & { onClose: () => void }): JSX.Element {
  const [children, setChildren] = useState<CyclabilityZone[]>();
  const {
    zone: { map: zoneMap, childrenMap, current: currentZone },
  } = useContext(AppContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.open && currentZone && childrenMap[currentZone.id]) {
      const ids = childrenMap[currentZone.id];
      if (!ids) return setChildren(undefined);
      else {
        const _children = ids
          .map((id) => zoneMap[id])
          .filter(Boolean)
          .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

        setChildren(_children);
      }
    }

    return () => setChildren(undefined);
  }, [props.open, currentZone, childrenMap]);

  return (
    <Dialog {...props} onClose={onClose}>
      <DialogTitle>{t('bicycle_facilities.stats.evolution_dialog.title')}</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection="column" gap={2}>
          {children
            ? children.map((child) => <ChildCard child={child} key={child.id} />)
            : [0, 1, 2].map((key) => <ChildCard key={`skeleton-${key}`} />)}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="outlined">
          {t('commons.actions.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type TChildCardRef = {
  toggleDataSetIndex: (datasetIndex: number, hidden: boolean) => void;
};

function _ChildCard(
  {
    downloading,
    child,
  }: {
    child?: CyclabilityZone;
    downloading?: boolean;
  },
  ref: Ref<TChildCardRef>,
): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const [downloadDialogOpen, toggleDownloadDialog] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart>();
  const { t } = useTranslation();
  const { toDistance } = useUnits();
  const { downloadCSV } = useFileSaver();

  useImperativeHandle(ref, () => ({
    toggleDataSetIndex: (datasetIndex: number, hidden: boolean) => {
      if (hidden) chartRef.current?.hide(datasetIndex);
      else chartRef.current?.show(datasetIndex);
    },
  }));

  useEffect(() => {
    if (child) setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized && canvasRef.current && child) {
      chartRef.current = new Chart(canvasRef.current, {
        type: 'line',
        data: {
          labels: child.stats.map(({ date }) => date.format('lll')),
          datasets: [
            {
              label: t('commons.facilities.cycleway_other'),
              data: child.stats.map(({ distances: { cycleways } }) => cycleways * 1000),
              backgroundColor: '#165DFF',
              borderColor: '#165DFF',
              pointRadius: 1,
            },
            {
              label: t('commons.facilities.greenway_other'),
              data: child.stats.map(({ distances: { greenways } }) => greenways * 1000),
              backgroundColor: '#0FC6C2',
              borderColor: '#0FC6C2',
              pointRadius: 1,
            },
            {
              label: t('commons.facilities.lane_other'),
              data: child.stats.map(({ distances: { lanes } }) => lanes * 1000),
              backgroundColor: '#F7BA1E',
              borderColor: '#F7BA1E',
              pointRadius: 1,
              hidden: true,
            },
            {
              label: t('commons.facilities.opposite_other'),
              data: child.stats.map(({ distances: { opposites } }) => opposites * 1000),
              backgroundColor: '#50CD89',
              borderColor: '#50CD89',
              pointRadius: 1,
              hidden: true,
            },
            {
              label: t('commons.facilities.shared_busway_other'),
              data: child.stats.map(({ distances: { sharedBusways } }) => sharedBusways * 1000),
              backgroundColor: '#5db5e3',
              borderColor: '#5db5e3',
              pointRadius: 1,
              hidden: true,
            },
            {
              label: t('commons.facilities.mixed_facilities_other'),
              data: child.stats.map(({ distances: { mixedfacilities } }) => mixedfacilities * 1000),
              backgroundColor: '#D91AD9',
              borderColor: '#D91AD9',
              pointRadius: 1,
              hidden: true,
            },
            {
              label: 'Total',
              data: child.stats.map(({ distances: { all } }) => all * 1000),
              backgroundColor: grey[200],
              borderColor: grey[200],
              pointRadius: 1,
              fill: true,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              ticks: {
                callback: (val, index) => child.stats[index].date.format('YYYY-MM'),
                maxRotation: 0,
              },
            },
            y: {
              beginAtZero: true,
              stacked: false,
              ticks: {
                callback: (value) => toDistance(value),
              },
            },
          },
          interaction: {
            intersect: false,
            mode: 'index',
          },
          plugins: {
            tooltip: {
              enabled: !downloading,
              callbacks: {
                title: ([{ dataIndex }]) => child.stats[dataIndex].date.format('L'),
                label: ({ dataset, parsed: { y } }) => {
                  return `${dataset.label} : ${toDistance(y)}`;
                },
              },
            },
            legend: {
              position: 'top',
              labels: {
                filter: (item) => {
                  return !downloading || !item.hidden;
                },
              },
              onClick: (_, { datasetIndex, hidden }) => {
                if (!downloading && typeof datasetIndex === 'number') {
                  if (hidden) chartRef.current?.show(datasetIndex);
                  else chartRef.current?.hide(datasetIndex);
                }
              },
            },
          },
        },
      });
    }

    return () => {
      chartRef.current?.destroy();
    };
  }, [initialized]);

  function handleDownload() {
    if (!child) return;

    downloadCSV(
      `${`${t('bicycle_facilities.stats.evolution_dialog.title')}_${child.name}`
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .replace(/\s+/g, '_')}.csv`,
      [
        'Date',
        ...facilities.map(
          ({ label, labelKey }) => `${label || (labelKey && t(labelKey)) || ''} (km)`,
        ),
      ],
      child.stats.map(({ date, distances }) => [
        date.format('L'),
        ...facilities.map(({ key }) => distances[key]),
      ]),
    );
  }

  return (
    <>
      <Card variant="outlined">
        <CardHeader title={child ? child.name : <Skeleton variant="text" width={200} />} />
        <CardContent>
          {child ? (
            <Box
              component="canvas"
              height={200}
              id={`chart-evolution-${child.id}`}
              ref={canvasRef}
            />
          ) : (
            <Skeleton height={200} variant="rectangular" />
          )}
        </CardContent>
        {!downloading && (
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button
              onClick={handleDownload}
              size="small"
              startIcon={<Download />}
              variant="outlined"
            >
              CSV
            </Button>
            <Button
              onClick={() => toggleDownloadDialog(true)}
              size="small"
              startIcon={<Download />}
              variant="outlined"
            >
              PNG
            </Button>
          </CardActions>
        )}
      </Card>
      {!downloading && child && (
        <DownloadDialog
          fullWidth
          child={child}
          maxWidth="md"
          onClose={() => toggleDownloadDialog(false)}
          open={downloadDialogOpen}
        />
      )}
    </>
  );
}

const ChildCard = forwardRef(_ChildCard);

function DownloadDialog({
  child,
  onClose,
  ...props
}: Omit<DialogProps, 'onClose'> & { child: CyclabilityZone; onClose: () => void }): JSX.Element {
  const [facilitiesChecked, setFacilitiesChecked] = useState<{ [key: string]: boolean }>({
    cycleways: true,
    greenways: true,
    all: true,
  });
  const [downloading, setDownloading] = useState(false);
  const cardRef = useRef<TChildCardRef>(null);
  const divToPrintRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { downloadBlob } = useFileSaver();

  async function handleDownload() {
    setDownloading(true);

    if (divToPrintRef.current) {
      const canvas = await html2canvas(divToPrintRef.current, {
        width: 1500,
        useCORS: true,
      });

      const blob = await new Promise<Blob>((resolve, reject) => {
        try {
          canvas.toBlob(
            (blob) => {
              if (!blob) throw new Error('no blob');
              else resolve(blob);
            },
            'image/png',
            1.0,
          );
        } catch (err) {
          reject(err);
        }
      });

      downloadBlob(
        `${`${t('bicycle_facilities.stats.evolution_dialog.title')}_${child.name}`
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .replace(/\s+/g, '_')}.png`,
        blob,
      );
    }

    setDownloading(false);
  }

  return (
    <Dialog {...props} onClose={onClose}>
      <DialogTitle>{t('bicycle_facilities.stats.evolution_dialog.title_download')}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box columnGap={2} display="flex" flexWrap="wrap">
          {facilities.map(({ key, label, labelKey }, index) => (
            <Box key={key}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={facilitiesChecked[key] || false}
                    color="primary"
                    onChange={(_, checked) => {
                      setFacilitiesChecked({ ...facilitiesChecked, [key]: checked });
                      cardRef.current?.toggleDataSetIndex(index, !checked);
                    }}
                    size="small"
                  />
                }
                label={label || (labelKey && t(labelKey))}
              />
            </Box>
          ))}
        </Box>
        <Divider />
        <Box alignSelf="center" width={750}>
          <Box ref={divToPrintRef} sx={{ zoom: '50%' }}>
            <ChildCard downloading child={child} ref={cardRef} />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="outlined">
          {t('commons.actions.cancel')}
        </Button>
        <Button
          color="primary"
          disabled={downloading}
          onClick={handleDownload}
          startIcon={
            downloading ? (
              <CircularProgress color="inherit" size={16} thickness={4} />
            ) : (
              <Download />
            )
          }
          variant="contained"
        >
          {t('commons.actions.download')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EvolutionDialog;
